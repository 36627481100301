exports.components = {
  "component---src-pages-404-index-js": () => import("./../../../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-impact-index-js": () => import("./../../../src/pages/impact/index.js" /* webpackChunkName: "component---src-pages-impact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sent-index-js": () => import("./../../../src/pages/sent/index.js" /* webpackChunkName: "component---src-pages-sent-index-js" */),
  "component---src-pages-works-index-js": () => import("./../../../src/pages/works/index.js" /* webpackChunkName: "component---src-pages-works-index-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */)
}

